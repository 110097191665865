export const INITIAL_PAGE_NUMBER = 1;
export const INITIAL_PER_PAGE = 12;

export const MOBILE_BREAKPOINT = '(max-width: 600px)';

export const STORAGE_TOKENS = 'zakroma';
export const STORAGE_ACCESS_TOKEN = 'accessToken';
export const STORAGE_REFRESH_TOKEN = 'refreshToken';
export const STORAGE_REDIRECT_PATH = 'zakroma-redirect-path';

// Время между запросами кода подтверждения, миллисекунды
export const TIME_BETWEEN_REQUESTS_SMS = 60 * 1000;

export const CONFIFM_CODE_LENGTH = 6;
