import { Component, input } from '@angular/core';

import { TClassification } from '@models/commonTypes';

@Component({
  selector: 'app-class-label',
  standalone: true,
  imports: [],
  templateUrl: './class-label.component.html',
  styleUrl: './class-label.component.scss',
})
export class ClassLabelComponent {
  classification = input<TClassification | null>();
}
