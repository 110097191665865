import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OverlayModule } from '@angular/cdk/overlay';

import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SearchComponent } from '../search/search.component';
import { ReferencesService } from '@services/references.service';
import { ButtonComponent } from '../button/button.component';
import { DrawerComponent } from '@components/common/drawer/drawer.component';
import { MOBILE_BREAKPOINT } from '@settings/constants';
import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-select-location',
  standalone: true,
  imports: [
    OverlayModule,
    SearchComponent,
    MatIconModule,
    ButtonComponent,
    MatCheckboxModule,
    DrawerComponent,
  ],
  templateUrl: './select-location.component.html',
  styleUrl: './select-location.component.scss',
})
export class SelectLocationComponent implements OnInit {
  constructor(
    private references: ReferencesService,
    private uiService: UIService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  locationsList = this.references.regionsList;
  filterLocationsList = signal(this.locationsList() || []);

  onSearch(search: string) {
    if (search) {
      this.filterLocationsList.set(
        this.locationsList()?.filter((region) =>
          region.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        ) || [],
      );
    } else {
      this.filterLocationsList.set(this.locationsList() || []);
    }
  }

  isOpen = signal(false);
  toggleIsOpen(): void {
    this.isOpen.update((prev) => !prev);
    if (this.isMobile()) this.uiService.toggleMainScroll();
  }

  destroyRef = inject(DestroyRef);
  isMobile = signal(false);

  ngOnInit(): void {
    this.breakpointObserver
      .observe([MOBILE_BREAKPOINT])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: BreakpointState) => {
        this.isMobile.set(state.breakpoints[MOBILE_BREAKPOINT]);
      });
  }
}
