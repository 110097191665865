import { Component, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { SearchComponent } from '@components/ui/search/search.component';

@Component({
  selector: 'app-drawer',
  standalone: true,
  imports: [MatSidenavModule, MatIcon, MatToolbarModule, SearchComponent],
  templateUrl: './drawer.component.html',
  styleUrl: './drawer.component.scss',
})
export class DrawerComponent {
  title = input.required<string>();
  searchPlaceholder = input<string>('Введите значение');

  searchText = output<string>();

  opened = input.required<boolean>();

  closeClick = output();
  closeClickFunction() {
    this.closeClick.emit();
  }
}
